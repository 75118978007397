import React from 'react'

import { Link, navigate } from "gatsby";

import {
  Seo,
} from '@components'
import { PageLayout } from '../../../components/site/layout/page-layout';
import { 
  AppShieldingBanner, 
  RightSidebar 
} from '../../../components/site/product';

import { BenefitCard } from "../../../components/core/benefit-card";
import { HackingProtectionIcon } from '../../../components/core/icons/hacking_protection'
import { BanEmulatorIcon } from '../../../components/core/icons/ban_emulator'
import { BlockAppIcon } from '../../../components/core/icons/block_app'
import { StopIcon } from '../../../components/core/icons/stop'


const AppShieldingPage = ({ location }) => {
  return (
    <PageLayout pathname={location.pathname}>
        <AppShieldingBanner title="App Shielding"/>
        
        <div className="container pt-[6rem] pb-[70px] text-[#262727]">
        <div className="w-full flex flex-col lg:flex-row px-[32px] gap-[38px]">
          {/* Left Side */}
          <div className="w-full">
              <div className="flex flex-col px-[20px] md:px-[28px] py-[30px] md:py-[40px] items-start gap-[15px] rounded-[10px] bg-[#F2F5F9]">
                  <h2 className="text-[31px] text-[#1B3D57] font-semibold leading-[43px] m-0">
                    App Shielding
                  </h2>
                  <div className="w-full xs:w-[230px] h-[4px] bg-[#B21C7A]"></div>
                  <p className="text-[17px] leading-[29px] m-0">
                    App Shielding is a set of technologies to secure an app at runtime, allowing detection of modifications, tampering and use of the tools associated with reverse engineering or dynamic analysis.
                  </p>
              </div>

              <h2 className="mt-[50px] text-[31px] text-[#1B3D57] font-semibold leading-[43px] m-0">Benefits</h2>
              <div className="mt-[25px] w-full flex flex-col md:flex-row gap-[18px]">
                <BenefitCard 
                  className="md:w-1/2"
                  Icon={
                    <HackingProtectionIcon className="w-[55px] h-[55px] flex-shrink-0" />
                  }
                  title="Hacking Protection"
                  text="Protect against all forms of app tampering, including fake apps"
                />
                <BenefitCard
                  className="md:w-1/2"
                  Icon={
                    <BanEmulatorIcon className="w-[55px] h-[55px] flex-shrink-0" />
                  }
                  title="Banning of Emulators and Simulators"
                  text="Control what devices your app can run on, including banning of emulators and simulators"
                />
              </div>
              <div className="mt-[25px] w-full flex flex-col md:flex-row gap-[18px]">
                <BenefitCard 
                  className="md:w-1/2"
                  Icon={
                    <BlockAppIcon className="w-[55px] h-[55px] flex-shrink-0" />
                  }
                  title="Blocking Apps"
                  text="Optionally block apps running on jailbroken or rooted devices"
                />
                <BenefitCard 
                  className="md:w-1/2"
                  Icon={
                    <StopIcon className="w-[55px] h-[55px] flex-shrink-0" />
                  }
                  title="Ban Hacking Tools and Frameworks"
                  text="Detect and ban known hacking tools and frameworks"
                />
              </div>
              <div className="w-full flex justify-center mt-[40px]">
                <button className="text-[16px] text-[#fff] font-medium leading-[28px] bg-[#78A65A] px-[38px] py-[16px] rounded-[7px]"
                  onClick={() => {
                    navigate('https://approov.io/info/schedule-a-demo')
                  }}
                >
                  Request a Demo
                </button>
              </div>

              <h2 className="mt-[40px] text-[31px] text-[#1B3D57] font-semibold leading-[43px] m-0">Integrity Verification</h2>
              <p className="mt-[20px] mb-[0px] text-[17px] leading-[29px]">
                Approov performs advanced runtime memory analysis to verify the integrity of the app and the environment in which it is running. Collected measurements are analyzed in real time by our cloud service which performs the verification. By offloading this analysis into the cloud, Approov can provide stronger and more flexible defense than solutions that only harden the app.
              </p>
              
              <h2 className="mt-[40px] text-[31px] text-[#1B3D57] font-semibold leading-[43px] m-0">App Tamper Detection</h2>
              <p className="mt-[20px] mb-[0px] text-[17px] leading-[29px]">
                Approov's analysis ensures your untampered official app is present. This prevents unofficial app modifications and gives you complete control over which specific versions of your app are accepted. Scripts will never be able to spoof traffic as if it were coming from your real app.
              </p>

              <h2 className="mt-[40px] text-[31px] text-[#1B3D57] font-semibold leading-[43px] m-0">Repackaging Prevention</h2>
              <p className="mt-[20px] mb-[0px] text-[17px] leading-[29px]">
                The app tamper analysis provides a positive verification that your app is present. This prevents repackaging modification and fake app attacks, ensuring you are fully in control of which apps can be used to access your backend API resources.
              </p>

              <h2 className="mt-[40px] text-[31px] text-[#1B3D57] font-semibold leading-[43px] m-0">Jailbreak and Root Detection</h2>
              <p className="mt-[20px] mb-[0px] text-[17px] leading-[29px]">
                Detects if your app is running on a <Link href="https://approov.io/blog/root-and-jailbreak-to-ban-or-not-to-ban" className="">jailbroken iOS device or a rooted Android device</Link>. Such devices pose considerable extra risk, as enhanced privileges allow more advanced hacking tools to run that compromise your app’s sandbox. Such devices may allow data theft from your app or interference with its operation. Approov provides advanced Android Magisk detection, even when Magisk is fully cloaked.
              </p>

              <h2 className="mt-[40px] text-[31px] text-[#1B3D57] font-semibold leading-[43px] m-0">Debug Detection</h2>
              <p className="mt-[20px] mb-[0px] text-[17px] leading-[29px]">
                Detect any attempt to connect a debugger to your running app, using a variety of detection techniques and defenses. This removes the risk of data exfiltration and malicious manipulation.
              </p>

              <h2 className="mt-[40px] text-[31px] text-[#1B3D57] font-semibold leading-[43px] m-0">Emulator Detection</h2>
              <p className="mt-[20px] mb-[0px] text-[17px] leading-[29px]">
                Real users of your app won’t be using an emulator. Hackers will because it allows easier manipulation of the environment, including the underlying operating system. Approov employs various techniques to detect if the app is running in such a risky environment.
              </p>

              <h2 className="mt-[40px] text-[31px] text-[#1B3D57] font-semibold leading-[43px] m-0">Hacking Framework Detection</h2>
              <p className="mt-[20px] mb-[0px] text-[17px] leading-[29px]">
                There are a wide range of reverse engineering and function hooking tools available for both iOS and Android. Approov has a wide range of detections for these, <Link href="https://approov.io/for/api-security-pillars" className="">detecting Frida, Xposed, Cydia</Link> and others. Approov also has defenses against memory dumping approaches.
              </p>

              <h2 className="mt-[40px] text-[31px] text-[#1B3D57] font-semibold leading-[43px] m-0">Cloner App Detection</h2>
              <p className="mt-[20px] mb-[0px] text-[17px] leading-[29px]">
                There are a large number of <Link href="https://approov.io/blog/cloner-apps-playing-in-a-shared-sandbox" className="">cloner apps</Link> for Android, enabling the running of multiple instances of your app on a single device. Cloner apps are extremely dangerous from a security perspective since they fundamentally undermine Android sandbox security guarantees, allowing attacks from the cloner app itself or between apps installed in the same cloner.
              </p>

              {/* <div className="mt-[70px] flex justify-center px-[90px] py-[40px] items-start gap-[15px] rounded-[10px] bg-[#F2F5F9]">
                  <h2 className="text-center text-[31px] text-[#1B3D57] font-semibold leading-[43px] m-0">
                    Recommended Resources About Approov Mobile RASP Security
                  </h2>
              </div> */}
          </div>
          {/* Right Side */}
          <RightSidebar location={location.pathname} />
        </div>
      </div>
    </PageLayout>
  )
}

export default AppShieldingPage
